
import React, { useState } from 'react';
import twitter from "../logos/twitter.jpg";
import facebook from "../logos/facebook.jpg";
import insta from "../logos/insts.jpg";
import { Router, Routes, Route, Link } from "react-router-dom";
import Home from '../files/homee.js';
import Contact from '../files/contact';
import About from '../files/about';
import "./navigation.css";
import logo from '../logos/logo.jpeg';

function Navigation() {
  const [message, setMessage] = useState('');



  return (
    <div>

    <div class="row">
        <div class="column">
        <div class="logo">
            Pramitra <span>Software Services</span>
        </div>
        </div>
        <div class="column">
            <div style={{float:'right',marginRight: '112px'}}>
                <nav style={{ padding: '10px' }}>
                <Link to="/" class="navlink" >Home</Link>
                <Link to="/about" class="navlink">About</Link>
                <Link to="/contact" class="navlink">Contact</Link>
                </nav>
            </div>
        </div>
    </div>
    <div style={{ padding: '20px' }}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </div>

      
    </div>
  );
}

export default Navigation;
