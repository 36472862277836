// src/components/Contact.js
import React, { useState } from 'react';
import "./contact.css";


function Contact() {
    function Submit(e) {
        const formEle = document.querySelector("form");
        const formDatab = new FormData(formEle);
        console.log('formEle: ',formEle);
        console.log('formDatab: ',formEle);
        e.preventDefault();
        fetch(
          "https://script.google.com/macros/s/AKfycbwhRTZi1uBLB7Rg14180NhpDRZnWaj7Q8Bh-7cp9qz4hg4yMGMq5mCvJ-pZfLUofcdLHQ/exec",
          {
            method: "POST",
            body: formDatab
          }
        )
          .then((res) => res.json())
          .then((data) => {
            console.log(data);
          })
          .catch((error) => {
            console.log(error);
          });

      document.getElementById('contact-form').style.display = 'none';
      document.getElementById('success-message').style.display = 'block';

      }

  return (
    <div>
        <header></header>
      <div class="container1">
            <div class="address-card">
            <h2>Our Address</h2>
            <p>
                Pramitra Software Services<br/>
                123 Business Street, Suite 101<br/>
                Tech City, Country 12345<br/>
                Phone: +1 (123) 456-7890<br/>
                Email: info@pramitra.com
            </p>
            </div>

            <div class="form-card">
            <h2>Contact Us</h2>
            
            <form id="contact-form" onSubmit={(e) => Submit(e)}>

                <div class="form-group">
                <label for="firstname">First Name</label>
                <input type="text" id="firstname" name="Name" placeholder="Enter your name" required/>
                </div>

                <div class="form-group">
                <label for="email">Email</label>
                <input type="email" id="email" name="Email" placeholder="Enter your email" required/>
                </div>

                <div class="form-group">
                <label for="phone">Phone Number</label>
                <input type="tel" id="phone" name="Phone" required/>
                </div>

                <div class="form-group">
                <label for="message">Message</label>
                <textarea id="message" name="Message" placeholder="Enter your message" required></textarea>
                </div>

                <button type="submit" class="submit-btn">Submit</button>
            </form>

            <div id="success-message" class="success-message" style={{display:'none'}}>
                Response Submitted Successfully. We will contact you back within 24 working hours. Thank you for reaching out to us.
            </div>
    


            </div>

            </div>
    </div>
  );
}

export default Contact;
