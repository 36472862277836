// src/components/Home.js
import React, { useState } from 'react';
import "./home.css";
import image from "../images.jpg"

function Home() {
  const [count, setCount] = useState(0);

  const handleIncrement = () => setCount(count + 1);
  const handleDecrement = () => setCount(count - 1);

  return (
    <div>
  <title>Software Service Homepage</title>
  <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap" rel="stylesheet"></link>

  <section class="hero">
    <div>
      <h1>Transform Your Business with Our Software</h1>
      <p>Our software solutions help you streamline your operations, enhance productivity, and unlock new opportunities. Get started today and experience the future of business technology.</p>
      <button class="cta-btn" onclick="window.location.href='#contact'">Get Started</button>
    </div>
    <img src={image} alt="Software Illustration" style={{maxWidth: '500px'}}/>
  </section>

  
  <section class="features">
    <div class="feature-card">
      <h3>Automation</h3>
      <p>Automate your workflows and reduce manual effort, saving time and improving efficiency.</p>
    </div>
    <div class="feature-card">
      <h3>Data Analytics</h3>
      <p>Gain deep insights into your business performance with our powerful analytics tools.</p>
    </div>
    <div class="feature-card">
      <h3>Cloud Integration</h3>
      <p>Easily integrate with various cloud services to ensure smooth data synchronization.</p>
    </div>
  </section>


    </div>
  );
}

export default Home;
