
import React from 'react';
import "./about.css";

function About() {
  return (
    <div>
      <div class="container">

        
        <div class="carousel-container">
        <div class="carousel">
            <div class="carousel-slide">Innovation at its Best</div>
            <div class="carousel-slide">Excellence in Every Line of Code</div>
            <div class="carousel-slide">Helping Your Business Grow</div>
        </div>
        </div>

        
        <section class="about-cards">
        <div class="about-card">
            <h2>Our Mission</h2>
            <p>Pramitra Software Services is dedicated to delivering innovative and efficient software solutions to businesses. Our mission is to help our clients enhance productivity, reduce costs, and drive growth through cutting-edge technology and tailored solutions.</p>
        </div>

        <div class="about-card">
            <h2>Our Vision</h2>
            <p>We aim to become a global leader in the software services industry by continuously improving our offerings and meeting the ever-evolving needs of businesses worldwide. We strive for excellence in every project we undertake.</p>
        </div>

        <div class="about-card">
            <h2>Our Values</h2>
            <p>At Pramitra, we value integrity, customer satisfaction, collaboration, and innovation. These principles guide our work and help us build strong, lasting relationships with our clients.</p>
        </div>
        </section>

        
        <section class="hierarchy-tree">
        <div class="tree-node">CEO</div>
        <div class="tree-line-vertical"></div>
        <div class="tree-node">CTO</div>
        <div class="tree-line-vertical"></div>
        <div class="tree-node">CFO</div>
        </section>

        </div>
    </div>
  );
}

export default About;
