
import React, { useState } from 'react';
import "./footer.css";

function Footer() {
  
  return (
    <div>
      <footer>


<div class="section">
  <h3>About Us</h3>
  <p>We are Pramitra Software Services, providing innovative software solutions for businesses worldwide.</p>
</div>


<div class="section links">
  <h3>Links</h3>
  <a href="#">Home</a>
  <a href="#">Services</a>
  <a href="#">About Us</a>
  <a href="#">Contact</a>
</div>


<div class="section">
  <h3>Explore</h3>
  <a href="#">Blog</a>
  <a href="#">Careers</a>
  <a href="#">Testimonials</a>
  <a href="#">FAQ</a>
</div>


<div class="section map-container">
  <h3>Our Location</h3>
  
  <iframe
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3153.337250849513!2d-122.41941538468192!3d37.77492927975925!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80858184e67e2e57%3A0x1ee3c2671295b85a!2sSan%20Francisco%2C%20CA%2C%20USA!5e0!3m2!1sen!2sin!4v1617742990072!5m2!1sen!2sin"
    width="100%" height="100%" style={{border:0}} allowfullscreen="" loading="lazy"></iframe>
</div>

</footer>

<div class="copyright">
<p>&copy; 2024 Pramitra Software Services. All Rights Reserved. | <a href="#">Terms & Conditions</a></p>
</div>
    </div>
  );
}

export default Footer;
